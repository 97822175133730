import { Text } from '@atoms/index';
import { howToStartSteps } from '@helpers/homepageData';
import { Button } from '@ui/atoms/design-system';
import { useEffect, useState } from 'react';
import texts from '@components/Homepage/en.json';

function StartInvestingStep(props) {
    const [stepHighlighted, setStepHighlighted] = useState(1);

    useEffect(() => {
        const interval = setInterval(() => {
            setStepHighlighted((stepHighlighted > 2 ? 0 : stepHighlighted) + 1);
        }, 2000);

        //Clearing the interval
        return () => clearInterval(interval);
    }, [stepHighlighted]);

    const StartInvestingButton = ({
        displayClass = '',
        widthClass = 'w-min',
    }) => (
        <Button
            buttonText={texts?.StartInvestingButton}
            buttonType="primary"
            className={`mt-2 ${displayClass}`}
            onClick={props?.onButtonClick}
            widthClass={widthClass}
            buttonSize={'small'}
        />
    );

    return (
        <div className="grid grid-cols-12 max-w-6xl xl:max-w-7xl mx-auto md:px-6 xl:px-20 mt-12">
            <div className="col-span-full md:col-span-6 xl:col-span-5 flex flex-col justify-center">
                <div className="flex flex-col gap-2 md:justify-start justify-center">
                    <div>
                        <Text
                            content={texts?.StartInvestingText}
                            className="h5-regular md:h3-regular md:text-left text-center text-primary-500"
                            htmlTag='h3'
                        />
                        <Text
                            content={texts?.InvestmentTime}
                            className="h2-semibold md:d3-semibold md:text-left text-center text-primary-500"
                        />
                    </div>
                    <Text
                        content={texts?.StartInvestingDescription}
                        className="p5-regular md:p4-regular md:text-left text-center text-gray-600 md:w-full xl:w-3/4 md:ml-0 md:mr-auto"
                        isInnerHtml
                    />

                    <StartInvestingButton
                        displayClass="md:block hidden"
                        widthClass="w-fit"
                    />
                </div>
            </div>
            <div className="col-span-full w-full xl:w-11/12 md:col-span-6 xl:col-span-7 flex flex-col gap-2 md:max-w-full max-w-[400px] px-8 md:px-4 xl:px-8 mx-auto">
                {howToStartSteps.map((item, index) => (
                    <div
                        key={index}
                        className={`flex w-full py-2 xl:py-2 items-center gap-4 xl:gap-8 ${
                            stepHighlighted === index + 1
                                ? 'p5-semibold md:p4-semibold'
                                : 'p5-regular md:p4-regular'
                        }`}
                    >
                        <div
                            className={`h1-semibold md:d1-semibold ${
                                index === 0 ? 'pl-1 mr-2' : ''
                            } ${
                                stepHighlighted === index + 1
                                    ? 'text-secondary-500'
                                    : 'text-secondary-50'
                            }`}
                        >
                            {item?.number}
                        </div>
                        <h5 className="text-gray-700">{item?.title}</h5>
                    </div>
                ))}
                <StartInvestingButton
                    displayClass="md:hidden block self-center"
                    widthClass="w-full"
                />
            </div>
        </div>
    );
}

export default StartInvestingStep;
